<template>
    <alliance-module-component/>
</template>

<script>
    import AllianceModuleComponent from "@/components/admin/AllianceModuleComponent";

    export default {
        name: "AllianceModule",
        title: "Alianzas Estratégicas | Private",
        components: { AllianceModuleComponent }
    }
</script>

<style scoped>

</style>